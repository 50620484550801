import React from "react";

import SocialNet from "./socialnet";

import styles from "../styles/share.module.scss";

const Share = () => {
	const url = encodeURIComponent('https://freethecodemonkeys.com/'),
		title = encodeURIComponent('Free the Code Monkeys');

	return (
		<div className={styles.container}>
			<div className={styles.share}>
				<div className={styles.title}><div>Spread</div><div>the</div><div>Freedom</div></div>
				<div className={styles.socialnets}>
					<SocialNet className={styles.socialnet} net="whatsapp" url={`https://api.whatsapp.com/send?text=${url}`} />
					<SocialNet className={styles.socialnet} net="mail" url={`mailto:hello@criticaltechworks.com?subject=${title}&body=${url}`} />
					<SocialNet className={styles.socialnet} net="facebook" url={`https://www.facebook.com/sharer/sharer.php?u=${url}`} />
					<SocialNet className={styles.socialnet} net="twitter" url={`https://twitter.com/intent/tweet?text=${url}`} />
					{/* <SocialNet className={styles.socialnet} net="github" url={`${url}`} /> */}
					<SocialNet className={styles.socialnet} net="reddit" url={`http://www.reddit.com/submit?url=${url}&title=${title}`} />
					{/* <SocialNet className={styles.socialnet} net="discord" url={`${url}`} /> */}
				</div>
			</div>
		</div>
	);
};

export default Share;
