import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Head from "../components/head";
import Share from "../components/share";
import Video from "../components/video";

import styles from "../styles/index.module.scss";

const IndexPage = ({data}) => (
	<Layout>
		<Head title={data.site.siteMetadata.title} />

		<section className={styles.section}>
			<h1 className={styles.heading}>Free<br/>the Code<br/>Monkeys</h1>
			<h2 className={styles.subheading}>We believe that great software engineers need <strong>freedom</strong> to grow. They require an unrestricted environment in which they can explore their best weapon: <strong>their minds</strong>.</h2>
		</section>

		<section id="video" className={styles.videoSection}>
			<Video />
		</section>

		<Share />

		<section id="manifesto" className={styles.manifestoSection}>
			<img src={data.manifesto.publicURL} alt="Free the Code Monkeys manifesto" />
		</section>

		<section className={styles.boxSection}>
			<img src={data.hiringTitle.publicURL} alt="Critical Techworks is hiring (No code monkeys)" />
		</section>

		<section className={styles.infoSection}>
			<div className={styles.infoColumn}>
				<div className={styles.infoTitle}>We’re changing the way the world moves.</div>
				<div>
					Fusing technology and talent, we’re developing the next generation of software systems for the BMW Group’s future driving machines.
				</div>
			</div>
			<div className={styles.infoColumnWithBorder}>
				Fuelled by the principles of agile software development, we’ll use our freedom to think, disrupt and transform the way things are done. We’re clear about where we’re heading. We plan to change the way the world thinks and feels about cars: from autonomous driving to electrification, from car sharing services to connectivity. Get ready for a journey like no other.
			</div>
		</section>

		<section className={styles.section}>
			<div className={styles.teamTitle}>Work With Us</div>
		</section>

		<section id="jobs" className={styles.section}>
			<a className={styles.jobsLink} href="https://join.criticaltechworks.com/#job-list" target="_blank" rel="noopener noreferrer">Our jobs</a>
		</section>
	</Layout>
);

export const query = graphql`
	query HomePageQuery {
		site {
			siteMetadata {
				title
			}
		}
		manifesto: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "manifesto.svg" }) {
			publicURL
		}
		hiringTitle: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "hiring-title.svg" }) {
			publicURL
		}
		allMarkdownRemark {
			edges {
				node {
					id
					frontmatter {
						title
						type
						location
						url
					}
					html
				}
			}
		}
	}
`;

export default IndexPage;
